
const intelligenceData = {
'Logical-Mathematical': {
     definition: "The ability to understand and identify logical or numerical patterns.",
     environment: "Surroundings where one can engage in complex problem-solving, analytical thinking, and structured reasoning.",
     aacMethods: [
       "Modeling",
       "Storyboards",
       "Picture exchange"
      
     ],
     activities: {
       top5: [
         "Puzzles",
         "Board games",
         "Simple arithmetic",
         "Counting (frontward and backward)",
         "Grouping"
       ],
       all: [
         "Puzzles",
         "Board games",
         "Simple arithmetic",
         "Counting (frontward and backward)",
         "Grouping",
         "Organizing",
         "Building (legos)",
         "Science experiments",
         "Sorting and categorizing",
         "Tic-Tac-Toe",
         "Connect the Dots",
         "Solitaire",
         "Mazes",
         "Checkers, Mancala",
         "Matching games",
         "Estimation",
         "Dominoes",
         "Tangram",
         "Coin sorting",
         "Measuring Game"
       ]
     }
   },
   'Linguistic-Verbal': {
     definition: "The ability to use and understand words, which may involve reading, writing, and speaking.",
     environment: "Places that value strong written and spoken communication skills.",
     aacMethods: [
       "Storyboards",
       "Picture Exchange",
       "Intensive Interaction"
   
       
     ],
     activities: {
       top5: [
         "Conversing",
         "Reading",
         "Listening to music",
         "Rhyming",
         "Word games"
       ],
       all: [
         "Storytelling",
         "Reading aloud",
         "Listen to music",
         "Writing",
         "Word games",
         "Word/Picture match",
         "Labeling objects",
         "Singing",
         "Rhyming",
         "Telling jokes",
         "Debating",
         "Journaling",
         "Role-playing",
         "Interactive story games",
         "Word of the day",
         "Hangman",
         "Crossword puzzles",
         "Alphabet Game",
         "I Spy",
         "What Am I?"
       ]
     }
   },
   'Bodily-Kinesthetic': {
     definition: "The ability to use the body to learn, express, and manipulate objects.",
     environment: "Surroundings that engage one's sense of touch, movement, and physical activity.",
     aacMethods: [
       "Body language (gesturing)",
       "Hand-over-hand",
       "Hand-under-hand",
       "Tactile (touch) cueing",
       "Modeling"
     ],
     activities: {
       top5: [
         "Walking",
         "Chair dancing",
         "Building (models, legos)",
         "Sports (playing catch, kicking a ball)",
         "Cooking/Baking"
       ],
       all: [
         "Walking",
         "Chair dancing",
         "Building (models, legos)",
         "Sports (playing catch, kicking a ball)",
         "Cooking/Baking",
         "Jenga",
         "Writing",
         "Sculpting",
         "Cleaning",
         "Washing/drying dishes",
         "Fidget toys",
         "Acting out tasks",
         "Gardening",
         "Swimming",
         "Folding",
         "Puzzles",
         "Dynamic stretching",
         "Field trip",
         "Bingo",
         "Arts and crafts"
       ]
     }
   },
   'Naturalistic': {
     definition: "The ability to identify, observe, categorize, understand, and manipulate natural elements like plants, animals, and the environment.",
     environment: "Places that support and stimulate connections to the natural world.",
     aacMethods: [
       "This intelligence does not align with specific AAC strategies; however, people with naturalistic intelligence tend to communicate better when exposed to nature.",
      
     ],
     activities: {
       top5: [
         "Watching nature shows",
         "Gardening",
         "Spending time with animals",
         "Looking at nature magazines",
         "Nature crafts"
       ],
       all: [
         "Nature walk",
         "Gardening",
         "Spending time with animals",
         "Bird watching",
         "Birdhouse building",
         "Looking at nature magazines",
         "Watching nature shows",
         "Visiting nature-related places",
         "Nature crafts",
         "Picnicking",
         "Pick flowers",
         "Rock collecting",
         "Stargazing",
         "Nature photography",
         "Talking about nature",
         "Plant identification",
         "Environmental cleanups",
         "Insect observation",
         "Nature journaling",
         "Creating a nature habitat"
       ]
     }
   },
   'Interpersonal': {
     definition: "The ability to understand and interact with others in an effective way",
     environment: "Although these individuals interact effectively with others, they should be encouraged to spend time alone, which can help prevent overstimulation.",
     aacMethods: [
       "People with this intelligence best communicate where direct interaction with others is encouraged and valued.",
       
     ],
     activities: {
       top5: [
         "Watching television with others",
         "Team games",
         "Social gatherings",
         "Community projects"
       ],
       all: [
         "Group discussions",
         "Team games",
         "Social gatherings",
         "Peer mentoring",
         "Community projects"
       ]
     }
   },
   'Intrapersonal': {
     definition: "The ability to understand one’s own thoughts, feelings, and motivations, and to use that understanding to make decisions and communicate.",
     environment: "Although these individuals have a sensitivity to their own feelings, they are not loners.  They should be encouraged to spend time with others.  Individuals with intrapersonal intelligence strengths tend to overstimulate easily; therefore, if they are in a group setting, it may help to allow them breaks.",
     aacMethods: [
       "Journaling",
       "Personal choice boards",
       "Self-reflection aids",
       "Visual schedules",
       "Preference indicators"
     ],
     activities: {
       top5: [
         "Journaling",
         "Meditation",
         "Personal projects",
         "Goal setting",
         "Self-paced learning"
       ],
       all: [
         "Journaling",
         "Meditation",
         "Personal projects",
         "Goal setting",
         "Self-paced learning"
       ]
     }
   },
   'Visual-Spatial': {
     definition: "The ability to perceive, analyze, understand, store, and recall visual information.",
     environment: "Surroundings where one can use their ability to visualize and manipulate objects and ideas.",
     aacMethods: [
       "Picture Exchange",
       "Storyboards",
       "Body Language (Gesturing)",
       "Modeling",
       "Intensive Interaction"
     ],
     activities: {
       top5: [
         "Puzzles",
         "Patterning",
         "Mazes",
         "Organizing",
         "Painting"
       ],
       all: [
         "Jigsaw puzzles",
         "Matching",
         "Mazes",
         "Board games",
         "Building (models, legos)",
         "Spatial sports",
         "Folding",
         "Bingo",
         "Painting",
         "Sketching",
         "Simon Says",
         "Movement games",
         "Patterning",
         "Looking at magazines",
         "Arts and crafts",
         "Visiting visually-appealing places",
         "Organizing",
         "Designing",
         "Spot the difference activities",
         "Eye spy"
       ]
     }
   },
   'Musical': {
     definition: "The ability to produce, discern, and transform sounds, rhythm, pitch, and melody.",
     environment: "Surroundings where one can express themselves through music, analyze sound, and create or perform musical pieces.",
     aacMethods: [
       "Body Language (Gesturing)",
       "Intensive Interaction"
      
     ],
     activities: {
       top5: [
         "Listen to music",
         "Play an instrument",
         "Name that Tune Game",
         "Humming",
         "Foot tapping"
       ],
       all: [
         "Listen to music",
         "Play an instrument",
         "Sing",
         "Whistle",
         "Rhythm activities",
         "Name that tune",
         "Make an instrument",
         "Talk about music",
         "Sound exploration",
         "Talk about song lyrics",
         "Explore music from different cultures",
         "Create a Sound Garden",
         "Humming relaxation"
       ]
     }
   }
 };

 export default intelligenceData;



/*const intelligenceData = {
    'Logical-Mathematical': {
      definition: "The ability to understand and identify logical or numerical patterns, analyze problems, perform mathematical calculations, and conduct scientific investigations.",
      environment: "Surroundings where one can engage in complex problem-solving, analytical thinking, and structured reasoning.",
      aacMethods: [
        "Modeling",
        "Storyboards",
        "Picture exchange",
        "Step-by-step instruction cards",
        "Visual schedules"
      ],
      activities: {
        top5: [
          "Puzzles",
          "Board games",
          "Simple arithmetic",
          "Counting (frontward and backward)",
          "Grouping"
        ],
        all: [
          "Puzzles",
          "Board games",
          "Simple arithmetic",
          "Counting (frontward and backward)",
          "Grouping",
          "Organizing",
          "Building (legos)",
          "Science experiments",
          "Sorting and categorizing",
          "Tic-Tac-Toe",
          "Connect the Dots",
          "Solitaire",
          "Mazes",
          "Checkers, Mancala",
          "Matching games",
          "Estimation",
          "Dominoes",
          "Tangram",
          "Coin sorting",
          "Measuring Game"
        ]
      }
    },
    'Linguistic-Verbal': {
      definition: "The ability to use words effectively, whether in writing or speech. Includes sensitivity to spoken and written language, the ability to learn languages, and capacity to use language to accomplish goals.",
      environment: "Language-rich surroundings with clear signage, labels, and opportunities for verbal expression and written communication.",
      aacMethods: [
        "Word cards",
        "Communication boards",
        "Written choices",
        "Picture-word matching",
        "Story sequencing"
      ],
      activities: {
        top5: [
          "Storytelling",
          "Reading aloud",
          "Listen to music",
          "Writing",
          "Word games"
        ],
        all: [
          "Storytelling",
          "Reading aloud",
          "Listen to music",
          "Writing",
          "Word games",
          "Word/Picture match",
          "Labeling objects",
          "Singing",
          "Rhyming",
          "Telling jokes",
          "Debating",
          "Journaling",
          "Role-playing",
          "Interactive story games",
          "Word of the day",
          "Hangman",
          "Crossword puzzles",
          "Alphabet Game",
          "I Spy",
          "What Am I?"
        ]
      }
    },
    'Bodily-Kinesthetic': {
      definition: "The ability to use the body to learn, express ideas, and manipulate objects. Includes physical skills such as coordination, balance, dexterity, strength, and speed.",
      environment: "Surroundings that engage one's sense of touch, movement, and physical activity, with space for safe movement and manipulation of objects.",
      aacMethods: [
        "Body language (gesturing)",
        "Hand-over-hand",
        "Hand-under-hand",
        "Tactile (touch) cueing",
        "Modeling"
      ],
      activities: {
        top5: [
          "Walking",
          "Chair dancing",
          "Building (models, legos)",
          "Sports (playing catch, kicking a ball)",
          "Cooking/Baking"
        ],
        all: [
          "Walking",
          "Chair dancing",
          "Building (models, legos)",
          "Sports (playing catch, kicking a ball)",
          "Cooking/Baking",
          "Jenga",
          "Writing",
          "Sculpting",
          "Cleaning",
          "Washing/drying dishes",
          "Fidget toys",
          "Acting out tasks",
          "Gardening",
          "Swimming",
          "Folding",
          "Puzzles",
          "Dynamic stretching",
          "Field trip",
          "Bingo",
          "Arts and crafts"
        ]
      }
    },
    'Naturalistic': {
      definition: "The ability to recognize and understand natural patterns, classify various species, and interact with living things and natural phenomena.",
      environment: "Nature-inspired settings with plants, natural light, and connections to outdoor spaces.",
      aacMethods: [
        "Nature pictures",
        "Sensory cues",
        "Object-based communication",
        "Natural sound cues",
        "Seasonal references"
      ],
      activities: {
        top5: [
          "Nature walk",
          "Gardening",
          "Spending time with animals",
          "Bird watching",
          "Birdhouse building"
        ],
        all: [
          "Nature walk",
          "Gardening",
          "Spending time with animals",
          "Bird watching",
          "Birdhouse building",
          "Looking at nature magazines",
          "Watching nature shows",
          "Visiting nature-related places",
          "Nature crafts",
          "Picnicking",
          "Pick flowers",
          "Rock collecting",
          "Stargazing",
          "Nature photography",
          "Talking about nature",
          "Plant identification",
          "Environmental cleanups",
          "Insect observation",
          "Nature journaling",
          "Creating a nature habitat"
        ]
      }
    },
    'Interpersonal': {
      definition: "The ability to understand and interact effectively with others, including sensitivity to others' moods, feelings, temperaments, and motivations.",
      environment: "Social spaces that encourage interaction while respecting personal boundaries and comfort levels.",
      aacMethods: [
        "Social stories",
        "Emotion cards",
        "Group cues",
        "Social scripts",
        "Partner-assisted communication"
      ],
      activities: {
        top5: [
          "Group discussions",
          "Team games",
          "Social gatherings",
          "Peer mentoring",
          "Community projects"
        ],
        all: [
          "Group discussions",
          "Team games",
          "Social gatherings",
          "Peer mentoring",
          "Community projects"
        ]
      }
    },
    'Intrapersonal': {
      definition: "The ability to understand oneself, including one's own feelings, motivations, and fears. Involves self-awareness and the capacity for personal goal-setting.",
      environment: "Quiet spaces for reflection and self-directed activities, with options for privacy when needed.",
      aacMethods: [
        "Emotion journals",
        "Personal choice boards",
        "Self-reflection aids",
        "Visual schedules",
        "Preference indicators"
      ],
      activities: {
        top5: [
          "Journaling",
          "Meditation",
          "Personal projects",
          "Goal setting",
          "Self-paced learning"
        ],
        all: [
          "Journaling",
          "Meditation",
          "Personal projects",
          "Goal setting",
          "Self-paced learning"
        ]
      }
    },
    'Visual-Spatial': {
      definition: "The ability to perceive the visual-spatial world accurately and perform transformations upon those perceptions.",
      environment: "Visually organized spaces with clear sight lines, visual cues, and artistic elements.",
      aacMethods: [
        "Visual schedules",
        "Picture cards",
        "Color coding",
        "Visual mapping",
        "Spatial organization aids"
      ],
      activities: {
        top5: [
          "Jigsaw puzzles",
          "Matching",
          "Mazes",
          "Board games",
          "Building (models, legos)"
        ],
        all: [
          "Jigsaw puzzles",
          "Matching",
          "Mazes",
          "Board games",
          "Building (models, legos)",
          "Spatial sports",
          "Folding",
          "Bingo",
          "Painting",
          "Sketching",
          "Simon Says",
          "Movement games",
          "Patterning",
          "Looking at magazines",
          "Arts and crafts",
          "Visiting visually-appealing places",
          "Organizing",
          "Designing",
          "Spot the difference activities",
          "Eye spy"
        ]
      }
    },
    'Musical': {
      definition: "The ability to recognize and create musical pitch, rhythm, timbre, and tone. Includes sensitivity to sounds from the environment.",
      environment: "Acoustically balanced spaces that allow for both musical expression and quiet reflection.",
      aacMethods: [
        "Musical cues",
        "Rhythm-based communication",
        "Song cards",
        "Musical choices",
        "Sound associations"
      ],
      activities: {
        top5: [
          "Listen to music",
          "Play an instrument",
          "Sing",
          "Whistle",
          "Rhythm activities"
        ],
        all: [
          "Listen to music",
          "Play an instrument",
          "Sing",
          "Whistle",
          "Rhythm activities",
          "Name that tune",
          "Make an instrument",
          "Talk about music",
          "Sound exploration",
          "Talk about song lyrics",
          "Explore music from different cultures",
          "Create a Sound Garden",
          "Humming relaxation"
        ]
      }
    }
  };
  
  export default intelligenceData;
  */