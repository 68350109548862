import React, { useRef, useState, useEffect, setErrors } from 'react';
import { ArrowLeft, Download, Loader2 } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { Calculator, Brain, Dumbbell, Leaf, Users, User, Eye, Music } from 'lucide-react';
import { jsPDF } from 'jspdf';
import 'jspdf/dist/polyfills.es.js';
import intelligenceData from './intelligenceData';
import ActivitiesList from './activitiesList';
import { PulseLoader } from 'react-spinners';


const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : '';

const useApi = (endpoint, requestData = null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
      setError(null);
    } catch (error) {
      console.error(`Error fetching from ${endpoint}:`, error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (requestData) {
      fetchData();
    }
  }, [endpoint, JSON.stringify(requestData)]);

  return { data, error, isLoading, refetch: fetchData };
};


const AICarePlan = ({ data, onClose }) => {


const NumberedCircle = ({ number }) => (
  <div className="flex items-center justify-center min-w-[24px] h-6 w-6 rounded-full bg-purple-100 border border-purple-300 text-purple-700 text-sm font-medium">
    {number}
  </div>
);

const InsightItem = ({ point, rationale, number }) => (
  <div className="mb-6">
    <div className="flex items-start gap-4">
      <NumberedCircle number={number} />
      <div className="flex-1">
        <p className="text-gray-800 font-medium mb-2">{point}</p>
        <p className="text-gray-600 italic text-sm pl-2 border-l-2 border-gray-200">
          Rationale: {rationale}
        </p>
      </div>
    </div>
  </div>
);
  const reportRef = useRef(null);

  const [errors, setErrors] = useState({});

  const { 
    data: summaryData, 
    error: summaryError, 
    isLoading: summaryLoading 
  } = useApi('api/generate-summary', {
    bioData: data.bioSummaryData,
    clientInfo: data.clientInfo
  });

  const { 
    data: recommendationsData, 
    error: recommendationsError, 
    isLoading: recommendationsLoading 
  } = useApi('api/generate-recommendations', {
    bioData: data.bioSummaryData,
    topIntelligences: data.topIntelligences.map(i => i.name),
    clientInfo: data.clientInfo 
  });


  // State for insights (needs special handling due to multiple calls)
  const [insightsLoading, setInsightsLoading] = useState(true);
  const [insightsError, setInsightsError] = useState(null);

  const [summary, setSummary] = useState('');
  const [personalizedInsights, setPersonalizedInsights] = useState({});
  const [recommendations, setRecommendations] = useState('');
  const [loading, setLoading] = useState({
    summary: true,
    insights: true,
    recommendations: true
  });

  const LoadingSection = ({ text }) => (
    <div className="flex items-center justify-center py-6 space-x-3">
      <PulseLoader color="#9333ea" size={10} speedMultiplier={0.8} />
      <span className="ml-3 text-gray-600 font-medium">{text}</span>
    </div>
  );

  // Error component
  const ErrorSection = ({ message }) => (
    <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
      <p className="text-red-600">Unable to load data: {message}</p>
    </div>
  );


   // Fetch insights for each intelligence type
   useEffect(() => {
    const fetchInsights = async () => {
      setInsightsLoading(true);
      try {
        const insightsData = {};
        
        // Fetch insights for primary intelligence
        const primaryResponse = await fetch(`${API_BASE_URL}/api/generate-care-insights-primary`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bioData: data.bioSummaryData,
            intelligence: data.topIntelligences[0].name,
            intelligenceAnswers: data.firstIntelligenceAnswers,
            clientInfo: data.clientInfo 
          })
        });

        // Fetch insights for secondary intelligence
        const secondaryResponse = await fetch(`${API_BASE_URL}/api/generate-care-insights-secondary`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bioData: data.bioSummaryData,
            intelligence: data.topIntelligences[1].name,
            intelligenceAnswers: data.secondIntelligenceAnswers,
            clientInfo: data.clientInfo 
          })
        });

        if (!primaryResponse.ok || !secondaryResponse.ok) {
          throw new Error('Failed to fetch insights');
        }

        const primaryData = await primaryResponse.json();
        const secondaryData = await secondaryResponse.json();

        insightsData[data.topIntelligences[0].name.replace(' Intelligence', '')] = 
          primaryData.insights.insights || [];
        insightsData[data.topIntelligences[1].name.replace(' Intelligence', '')] = 
          secondaryData.insights.insights || [];

        setPersonalizedInsights(insightsData);
        setInsightsError(null);
      } catch (error) {
        console.error('Error fetching insights:', error);
        setInsightsError(error.message);
      } finally {
        setInsightsLoading(false);
      }
    };

    if (data.topIntelligences?.length > 0) {
      fetchInsights();
    }
  }, [data.topIntelligences, data.bioSummaryData, data.firstIntelligenceAnswers, data.secondIntelligenceAnswers]);


  const interpersonalScore = data.intelligenceScores.find(score => score.name === 'Interpersonal Intelligence')?.percentage || 0;
  const intrapersonalScore = data.intelligenceScores.find(score => score.name === 'Intrapersonal Intelligence')?.percentage || 0;
  
  let interAndIntraPersonalData = [
    { name: 'Interpersonal', value: interpersonalScore, fill: '#D8B4BC' },
    { name: 'Intrapersonal', value: intrapersonalScore, fill: '#808080' }
  ];

  const getIntelligenceComparisonMessage = (data) => {
    const interpersonalScore = data.intelligenceScores.find(
      score => score.name === 'Interpersonal Intelligence'
    )?.percentage || 0;
    
    const intrapersonalScore = data.intelligenceScores.find(
      score => score.name === 'Intrapersonal Intelligence'
    )?.percentage || 0;
    
    const firstName = data.clientInfo.name.split(' ')[0];
  
    if (interpersonalScore > intrapersonalScore) {
      return `${firstName} possesses a higher level of Interpersonal Intelligence (${interpersonalScore.toFixed(1)}%) than Intrapersonal Intelligence (${intrapersonalScore.toFixed(1)}%). This higher interpersonal score means that ${firstName} is more likely to be successful when interacting with others. People with strong interpersonal intelligence often thrive in group activities, respond well to social cues, and find comfort in companionship. For ${firstName}, this suggests that care approaches should emphasize social engagement, group activities, and opportunities for meaningful interactions with others. Staff should encourage participation in small group activities, facilitate social connections with other residents, and use social interaction as a means of comfort during periods of distress. However, it's important to note that while ${firstName}'s Intrapersonal score is lower (${intrapersonalScore.toFixed(1)}%), some quiet time for self-reflection and independent activities should still be incorporated into the daily routine to maintain a balanced approach to care.`;
    } else if (intrapersonalScore > interpersonalScore) {
      return `${firstName} possesses a higher level of Intrapersonal Intelligence (${intrapersonalScore.toFixed(1)}%) than Interpersonal Intelligence (${interpersonalScore.toFixed(1)}%). This higher intrapersonal score means that ${firstName} is more likely to be successful when engaging in self-directed, independent activities. People with strong intrapersonal intelligence typically have a deep understanding of their own needs, preferences, and emotions, and may process experiences better through self-reflection. For ${firstName}, this suggests that care approaches should respect the need for personal space and independent decision-making. Activities should include opportunities for individual pursuits, quiet reflection, and self-directed tasks. Staff should be mindful to provide adequate alone time and not mistake a preference for solitude as social withdrawal. While ${firstName}'s Interpersonal score is lower (${interpersonalScore.toFixed(1)}%), gentle encouragement for selective social engagement can still be beneficial, but should be balanced with respect for personal boundaries and the need for independence.`;
    } else {
      return `${firstName} shows equal levels of Interpersonal and Intrapersonal Intelligence (${interpersonalScore.toFixed(1)}%). This balance suggests that ${firstName} may be equally comfortable with both social interactions and independent activities. This unique balance indicates adaptability and flexibility in engaging with different types of experiences. For optimal care, staff should alternate between group activities and solitary pursuits, paying careful attention to ${firstName}'s cues about which type of engagement is preferred at any given time. This balanced profile suggests that ${firstName} might benefit from a varied daily routine that includes both social interaction and quiet, reflective periods. Care approaches should be flexible, allowing ${firstName} to choose between group activities and independent pursuits based on current mood and energy levels.`;
    }
  };

  const maxValue = Math.max(...interAndIntraPersonalData.map(d => d.value));
  interAndIntraPersonalData = interAndIntraPersonalData.map(d => ({
    ...d,
    fill: d.value === maxValue ? '#6b21a8' : '#dbdbdb'
  }));

  

  function getIconForIntelligence(name) {
    switch (name) {
      case 'Logical-Mathematical':
        return <Calculator className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Linguistic-Verbal':
        return <Brain className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Bodily-Kinesthetic':
        return <Dumbbell className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Naturalistic':
        return <Leaf className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Interpersonal':
        return <Users className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Intrapersonal':
        return <User className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Visual-Spatial':
        return <Eye className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Musical':
        return <Music className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      default:
        return null;
    }
  }

  const getIntelligenceColor = (name) => {
    const colorMap = {
      'Logical-Mathematical Intelligence': '#D2B48C',
      'Linguistic-Verbal Intelligence': '#9D7FD9',
      'Bodily-Kinesthetic Intelligence': '#F6E05E',
      'Naturalistic Intelligence': '#68D391',
      'Interpersonal Intelligence': '#F8B4B4',
      'Intrapersonal Intelligence': '#A0AEC0',
      'Visual-Spatial Intelligence': '#63B3ED',
      'Musical Intelligence': '#ffb570'
    };
    return colorMap[name] || '#808080';
};

const allIntelligencesData = data.intelligenceScores.map(score => ({
    name: score.name.replace(' Intelligence', ''),
    Percentage: score.percentage,
    fill: getIntelligenceColor(score.name)
}));

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'letter',
      encryption: {
        userPassword: "password1234",
        ownerPassword: "password1234",
        userPermissions: ["print", "modify", "copy", "annot-forms"]
      }
    });

    const element = reportRef.current;
    if (!element) return;

    // Use the html() method to convert the HTML to PDF with selectable text
    await doc.html(element, {
      x: 10,
      y: 10,
      autoPaging: 'text',
      html2canvas: {
        // Adjust options if necessary
        scale: 1,
        useCORS: true
      }
    });

    doc.save('Report.pdf');
  };

  return (

    
    <div className="min-h-screen w-full bg-white flex flex-col">
      <style>
        {`
          @media print {
            .no-print {
              display: none !important;
            }
            body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              background: #fff;
            }
          }
        `}
      </style>

      {/* Header & Back/Download Buttons */}
      <div className="flex items-center justify-between px-6 py-4 md:px-10 md:py-6 no-print">
        <button
          onClick={onClose}
          className="flex items-center gap-2 text-gray-500 rounded-full px-3 py-2 hover:text-gray-700 transition-colors hover:bg-gray-100"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="text-sm font-semibold">Back to Results</span>
        </button>
        
        <button
          onClick={handleExportPdf}
          className="flex items-center gap-2 text-gray-500 rounded-full px-3 py-2 hover:text-gray-700 transition-colors hover:bg-gray-100"
        >
          <Download className="w-5 h-5" />
          <span className="text-sm font-semibold">Download as PDF</span>
        </button>
      </div>

      {/* Main Container for PDF */}
      <div ref={reportRef} className="w-[95%] max-w-5xl mx-auto mb-12 bg-white shadow-xl rounded-xl overflow-hidden p-6 md:p-10">
        {/* Header Section */}
        <div className="pb-8 border-b border-gray-200">
          <div className="space-y-4 text-center">
            <h1 className="text-3xl md:text-4xl font-bold text-purple-800 tracking-tight">
              The Center for Holistic Dementia Care
            </h1>
            <h2 className="text-teal-600 text-xl md:text-2xl font-medium">
              The Multiple Intelligence Survey for Persons with Dementia (MISPWD) Results
            </h2>
            <h3 className="text-gray-600 text-md md:text-lg">
              Prepared for Blue Heron Senior Living
            </h3>
          </div>

          {/* Client Info Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-6 mt-8 border-t border-gray-200 pt-10">
          <div>
          <span className="font-semibold text-gray-700">Name: </span>
          <span className="text-gray-900">{data.clientInfo.name}</span>
        </div>
        <div>
          <span className="font-semibold text-gray-700">Respondent: </span>
          <span className="text-gray-900">{data.clientInfo.respondent}</span>
        </div>
        <div>
          <span className="font-semibold text-gray-700">Surveyor: </span>
          <span className="text-gray-900">{data.clientInfo.surveyor}</span>
        </div>
        <div>
          <span className="font-semibold text-gray-700">Date: </span>
          <span className="text-gray-900">{data.clientInfo.date}</span>
        </div>
        </div>

        {/* Overview Section */}
        <div className="mt-10 space-y-6">
          <h2 className="text-purple-800 text-2xl font-bold">Overview of MISPWD Survey Goals</h2>
          <p className="text-gray-700 leading-relaxed">
            The Multiple Intelligence Survey for Persons with Dementia (MISPWD) eliminates the
            guesswork in care by identifying person-centered strategies, helping create the ideal care
            plan. The survey's most notable benefit is its ability to pinpoint the following:
          </p>

          <div className="space-y-6">
            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Comfortable Environments:</h3>
              <p className="text-gray-700">
                Intelligence-specific surroundings where people feel safe and secure to help them thrive.
              </p>
            </section>

            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Augmentative and Alternative Communication (AAC) Strategies:</h3>
              <p className="text-gray-700">
                Intelligence-specific strategies to help reinforce oral language for those who have trouble
                communicating verbally and methods that provide nonverbal people with a means to relay
                information.
              </p>
            </section>

            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Suitable Activities:</h3>
              <p className="text-gray-700">
                Intelligence-specific activities that align with how people learn and acquire information
                throughout their lives, increasing their chances of success.
              </p>
            </section>

            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Potential Benefits:</h3>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>Increased independence</li>
                <li>Decreased troublesome symptoms, e.g., confusion, anxiety, and agitation</li>
                <li>Improved self-worth</li>
                <li>Better ability to get needs and wants met</li>
                <li>Improved mental functioning</li>
              </ul>
            </section>
          </div>
        </div>

      {/* Summary Section */}
    <div className="mt-10 space-y-6">
      <h2 className="text-purple-800 text-2xl font-bold mb-4">
        Brief Summary about {data?.clientInfo?.name || 'Client'}
      </h2>
      
      {summaryLoading ? (
        <LoadingSection text="Generating personalized summary..." />
      ) : summaryError ? (
        <ErrorSection message={summaryError} />
      ) : summaryData?.summary ? (
        <div className="text-gray-700 leading-relaxed">
          {summaryData.summary}
        </div>
      ) : (
        <p className="text-gray-500 italic">No summary available.</p>
      )}
    </div>



        {/* Survey Results Section */}
        <div className="mt-10 space-y-8">
          <h2 className="text-purple-800 text-2xl font-bold">Survey Results</h2>

          {/* Intelligence Scores */}
          {/* Intelligence Scores */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
  <div className="text-xl font-semibold text-gray-800">
    Interpersonal Intelligence: <span className="text-purple-700">{interpersonalScore.toFixed(1)}/100</span>
  </div>
  <div className="text-xl font-semibold text-gray-800">
    Intrapersonal Intelligence: <span className="text-purple-700">{intrapersonalScore.toFixed(1)}/100</span>
  </div>
</div>

          {/* Chart */}
          <div className="mt-12 space-y-6">
            <div className="h-[400px] mb-8 border border-gray-200 p-4 rounded-lg overflow-visible">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={interAndIntraPersonalData}
                  layout="vertical"
                  margin={{ top: 20, right: 30, left: 100, bottom: 40 }}
                >
                  <CartesianGrid horizontal={false} stroke="#eeeeee" />
                  <XAxis
                    type="number"
                    domain={[0, 100]}
                    ticks={[0, 25, 50, 75, 100]}
                    stroke="#000"
                    label={{ value: 'Percentage', position: 'bottom', offset: 10 }}
                  />
                  <YAxis
                    type="category"
                    dataKey="name"
                    width={100}
                    stroke="#000"
                    tick={{ fontWeight: 'bold' }}
                  />
                  <Bar
                    dataKey="value"
                    barSize={100}
                    label={{ position: 'right', fill: '#000', fontSize: 14 }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <p className="text-gray-700 leading-relaxed">
            {getIntelligenceComparisonMessage(data)}
          </p>
        </div>

  {/* Top 2 Intelligences */}
<div className="mt-10 space-y-8">
  <h2 className="text-purple-800 text-2xl font-bold">Top 2 Intelligences and MI Care Plan Recommendations</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    {data.topIntelligences.map((intelligence, index) => {
      const intelligenceName = intelligence.name.replace(' Intelligence', '');
      const intelligenceInfo = intelligenceData[intelligenceName];
      const isFirstIntelligence = index === 0;
      const endpointUrl = isFirstIntelligence ? 
        '/api/generate-care-insights-primary' : 
        '/api/generate-care-insights-secondary';
      const intelligenceAnswers = isFirstIntelligence ? 
        data.firstIntelligenceAnswers : 
        data.secondIntelligenceAnswers;

      return (
        <div key={index} className="border border-gray-300 rounded-lg bg-white shadow-sm">
          {/* Intelligence header and basic info - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h3 className="font-bold text-gray-800">
              {getIconForIntelligence(intelligenceName)}
              {intelligenceName} {intelligence.percentage.toFixed(1)}%
            </h3>
            <br />
            <p className="text-gray-700 text-sm">
              {intelligenceInfo.definition}
            </p>
          </div>

          {/* Environment section - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h4 className="font-bold mb-2 text-purple-700">Comfortable Environment</h4>
            <p className="text-gray-700">
              {intelligenceInfo.environment}
            </p>
          </div>

          {/* AAC Methods section - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h4 className="font-bold mb-2 text-purple-700">AAC Methods</h4>
            <ol className="list-decimal list-inside text-gray-700 space-y-1">
              {intelligenceInfo.aacMethods.map((method, i) => (
                <li key={i}>{method}</li>
              ))}
            </ol>
          </div>

          {/* Activities section - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h4 className="font-bold mb-2 text-purple-700">Suitable Activities</h4>
            <ActivitiesList
              activities={intelligenceInfo.activities.top5}
              renderContent={() => (
                <ol className="list-decimal list-inside text-gray-700 space-y-1">
                  {intelligenceInfo.activities.top5.map((activity, i) => (
                    <li key={i}>{activity}</li>
                  ))}
                </ol>
              )}
              renderAllContent={() => (
                <ol className="list-decimal list-inside text-gray-700 space-y-1">
                  {intelligenceInfo.activities.all.map((activity, i) => (
                    <li key={i}>{activity}</li>
                  ))}
                </ol>
              )}
            />
          </div>

          {/* Updated Personalized Care Insights Section */}
          <div className="border-b border-gray-300 p-4">
                  <h4 className="font-bold mb-4 text-purple-700">
                    {isFirstIntelligence ? 'Primary Intelligence Insights' : 'Secondary Intelligence Insights'}
                  </h4>
                  {insightsLoading ? (
                    <LoadingSection text={`Generating ${isFirstIntelligence ? 'primary' : 'secondary'} intelligence insights...`} />
                  ) : insightsError ? (
                    <ErrorSection message={insightsError} />
                  ) : (
                    <div className="space-y-2">
                      {personalizedInsights[intelligenceName]?.map((insight, i) => (
                        <InsightItem 
                          key={i}
                          number={i + 1}
                          point={insight.point}
                          rationale={insight.rationale}
                        />
                      ))}
                    </div>)}
                    </div>
        </div>
      );
    })}
  </div>
</div>

{/* Additional Recommendations Section */}
    {/* Recommendations Section */}
    {recommendationsLoading ? (
      <LoadingSection text="Generating recommendations..." />
    ) : recommendationsError ? (
      <ErrorSection message={recommendationsError} />
    ) : recommendationsData?.recommendations ? (
      <div className="text-gray-700 leading-relaxed">
        {recommendationsData.recommendations.split('\n\n').map((paragraph, index) => (
          <p key={index} className="mb-4">{paragraph}</p>
        ))}
      </div>
    ) : (
      <p className="text-gray-500 italic">No recommendations available.</p>
    )}
    
        {/* Complete Survey Results */}
        <div className="mt-12 space-y-6">
          <h2 className="text-center text-2xl font-bold text-purple-800 mb-8">Survey Results - Percentages for 8 Intelligences</h2>
          <div className="h-[500px] mb-8 border border-gray-200 p-4 bg-gray-50 rounded-lg pt-10 pb-10">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={allIntelligencesData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 150, bottom: 5 }}
              >
                <CartesianGrid horizontal strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 100]} />
                <YAxis type="category" dataKey="name" width={140} />
                <Tooltip wrapperStyle={{ zIndex: 9999 }} />
                <Bar dataKey="Percentage" barSize={30} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Caveat & Disclaimer */}
          <div className="space-y-6 text-gray-700">
            <div>
              <span className="text-teal-600 font-bold">Caveat: </span>
              The goal is to optimize care; just because a person scores higher on one intelligence versus another doesn't mean that he/she should not be exposed to other intelligence-aligned activities.
            </div>

            <div>
              <span className="text-teal-600 font-bold">Disclaimer: </span>
              <em>These results contain educational information. Please use it as a supplement rather than a replacement for medical advice, diagnosis or treatment.</em>
            </div>

            <div className="text-sm text-gray-600">
              ❉ Multiple Intelligence definitions provided by{' '}
              <a href="https://mybrightwheel.com/" className="text-blue-600 hover:underline">
                https://mybrightwheel.com/
              </a>
              <br />
              The MISPWD© and MI Care are based on Dr. Howard Gardner's Theory of Multiple Intelligences.
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AICarePlan;
